











































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';

import {
  dispatchGetWidgets,
} from '@/store/affi/actions';
import {
    readWidgets,
    readImpersonated,
} from '@/store/affi/getters';
import {
    commitSetWidgets,
} from '@/store/affi/mutations';
import { readHasAdminAccess } from '@/store/main/getters';

import { ttt } from '@/nutils';

@Component
export default class PreviewWidget extends Vue {
  public ttt = ttt;

  public async mounted() {
    await dispatchGetWidgets(this.$store);

    const imp = readImpersonated(this.$store);
    const ws = readWidgets(this.$store)
        .filter( (obj) => ( imp === -1 || obj && obj.owner_id === imp ) );
    commitSetWidgets(this.$store, ws);
  }

  public cancel() {
    this.$router.back();
  }

  get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get widget() {
    const ws = readWidgets(this.$store);
    return ws[0];
  }

}
